import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import React, { memo,useEffect } from 'react'
import { Button, Checkbox, CheckboxProps, Dropdown } from 'semantic-ui-react'
import { 
    ROLES , 
    EQUIPMENT_TYPE, 
    APPLICATIN_TYPE, 
    equipment_application_CFSB_High_Risk, 
    RISK_LEVELS,
    BANK_CODES,
    application_type_nmi
} from '../../../Utils/constants'

interface EquipDefaultObj {
    connection_type: string;
    auto_close: string;
    ebt: string;
    pin_debit_required: string;
    equipment_mailing_addr_type: string;
    equipment_type: string;
    equipment_order_type: string;
    quantity: string;
    special_instructions: string;
    deployment: string;
    billTo: string;
    cost: string;
    application: string;
    product: string;
    pos_name: string;
    pos_version: string;
    pin_name: string;
    receipt_footer_info:string;
    check_reader_text: string;
    virtual_terminal_nmi: string;
    virtual_terminal_auth: string;
    virtual_terminal_other: string;
    tokenization_needed: boolean;
    purchasing_cards: boolean;
    avs: boolean;
    server_clerk: boolean;
    sn_man: string;
    sim_esn: string;
    wireless: boolean;
    invoice_number: boolean;
    cvv2: boolean;
    fraud_check: boolean;
    rm_added: boolean;
    mobile_equipment:string;
    ach_electronic_check:boolean;
    customer_vault:boolean;
    automatic_card_updater:boolean;
    invoicing:boolean;
    level_three_processing:boolean;
    key_injection_needed:boolean;
    tip_option:boolean;
    multi_merchant:boolean;
    pin_debit:boolean;
    cash_back_option:boolean;
}

interface EquipmentInformationSectionProps {
    role: string | null
    equipmentSectionData: EquipDefaultObj[]
    equipmentActive: number
    globalFormat: (value: any) => any
    getProductCost: (product: any) => string,
    getProductOptions:(value:string)=>{
        text: string;
        value: string;
      }[]
    setMinEquipmentCost: React.Dispatch<React.SetStateAction<number>>
    setEquipmentTotalCost: any
    setEquipmentSectionData: React.Dispatch<React.SetStateAction<any[]>>
    equip_default_obj: EquipDefaultObj
    setHideEquipment: React.Dispatch<React.SetStateAction<boolean>>
    setEquipmentActive: React.Dispatch<React.SetStateAction<number>>
    equipment_terminal: {
        text: string;
        value: string;
    }[]
    merchantWithEquip: boolean
    equipment_deployment:{[key:string]: {
        text: string;
        value: string;
    }[]}
    equipment_deployment_default:{
        [key:string]:string
    }
    condProductOptions: {
        text: string;
        value: string;
    }[]
    productOptions: any
    equipment_billTo: {
        text: string;
        value: string;
    }[]
    minEquipmentCost: number
    equipment_quantity: {
        text: string;
        value: string;
    }[]
    
    equipment_application: {[key:string]:{
        text: string;
        value: string;
    }[]}
    mailing_address: {
        text: string;
        value: string;
    }[]
    connection_type: {
        text: string;
        value: string;
    }[]
    
    equipmentTypeConditionalOptions: {
        text: string;
        value: string;
      }[]
      setEquipment: React.Dispatch<React.SetStateAction<string>>
      equipment: string
      equipmentTerminal: {
          text: string;
          value: string;
      }[],
      mobileEquipmentOptions:{
        text: string;
        value: string;
    }[]
    connection_type_physical:{
        text: string;
        value: string;
    }[]
    auto_close_default:any,
    format: "HH:mm",
    isMobile: boolean,
    isTab: boolean,
    selectedBankOption:string,
    selectedSellTime:string,    
    tokenID:string,
    riskLevel: string,
    config:any,
    pinDebit:string
}
const EquipmentInformationSection = (props: EquipmentInformationSectionProps) => {
    const {
        equipmentSectionData,
        role,
        equipmentActive,
        globalFormat,
        getProductCost,
        setMinEquipmentCost,
        setEquipmentTotalCost,
        setEquipmentSectionData,
        equip_default_obj,
        setEquipmentActive,
        setHideEquipment,
        equipment_terminal,
        merchantWithEquip,
        equipment_deployment,
        condProductOptions,
        productOptions,
        equipment_billTo,
        minEquipmentCost,
        equipment_quantity,
        equipment_application,
        mailing_address,
        connection_type,
        format,
        isMobile,
        isTab,
        equipmentTerminal,
        equipmentTypeConditionalOptions,
        setEquipment,
        getProductOptions,
        equipment,
        connection_type_physical,
        selectedBankOption,
        auto_close_default,
        mobileEquipmentOptions,
        selectedSellTime,
        equipment_deployment_default,
        tokenID,
        riskLevel,
        config,
        pinDebit
    } = props

    const RenderTabButton = (i: any) => {

        return <>
          <div className='three wide field'>
            <Button.Group floated='left'>
              <Button color='blue'className='ownerActive' inverted={equipmentActive === i? false : true} onClick= {()=> handleEquipmentClick(i)}>
                Equipment{i + 1}
              </Button>
              <Button color='blue' icon='close' onClick={() => handleRemoveEquipClick(i)} />
              {equipmentSectionData.length - 1 === i && equipmentSectionData.length < 4 && (
                <Button color='green' className='margin-left-10' inverted onClick={() => handleAddEquipClick(i)}>
                  +
                </Button>
              )}
            </Button.Group>
          </div>
        </>
      }

    // RENDER EQUIPMENT BUTTONS
    const renderEquipmentButton = (merchantWithEquip: boolean) => {
        return equipmentSectionData.map((item, i) => {
            let disableFlag = ((role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role === ROLES.SALES_LIMITED) || (!item?.rm_added && !role)) ? false : true
            const equipmentbuttoncolor = config?.client_config_fe?.button_color === '#F16B2E' ? 'orange' : 'blue'
            return (
                <div>
                    {!isTab ? <><div className='three wide field'>
                        <Button color={equipmentbuttoncolor} inverted={equipmentActive === i ? false : true} 
                        onClick={() => handleEquipmentClick(i)}>
                        
                            Equipment{i + 1}
                        </Button>
                        {equipmentSectionData.length - 1 === i && equipmentSectionData.length < 4 && (
                            <Button color='green' inverted onClick={() => handleAddEquipClick(i)}>
                                +
                            </Button>
                        )}
                    </div>
                    {(equipmentSectionData.length > 1 && <div className='three wide field'
                        style={{ paddingTop: '10px' }}>
                        <Button disabled={disableFlag} color='red' inverted onClick={() => handleRemoveEquipClick(i)}>
                            -
                        </Button>
                    </div>)} </> : <>
                        {RenderTabButton(i)}
                    </>}
                </div>
            )
        })
    }
    useEffect(()=>{
        const list: any = [...equipmentSectionData]
        let totalEquipmentCost: any = 0;
        list.forEach((element:any) => {
            if(element['billTo'] === 'Trinity'){                
                totalEquipmentCost += Number(element['cost']);
            }
        })
          setEquipmentTotalCost(totalEquipmentCost)
      },[minEquipmentCost,equipmentSectionData])

    // handle input change
    const updateEquipmentType=(e:any,index:any,type?:string)=>{
        if(type){
            setEquipment(type)
        }
    }
    const handleEquipChange = (e: any, index: any, data?: any, type?: string) => {
        const list: any = [...equipmentSectionData]
        if(data && data.name && data.value !== list[index]['product'] && (list[index].equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL) && !data.checked && data.value !== "NMI"){
            list[index]['application'] = "Full Gateway"
        }
        if(data && data.name && data.name === "equipment_type" && data.value !==list[index]['equipment_type']){
            setEquipment(data.value)
            list[index]["deployment"]=equipment_deployment_default[data.value]?equipment_deployment_default[data.value]:''
            list[index]['product']=''
            list[index]['application'] = ''
            list[index]['billTo'] = ''
            list[index]['quantity']=''
            list[index]['cost']=''
            list[index]['equipment_mailing_addr_type'] = ''
            list[index]['pos_name'] = ''
            if(data.value===EQUIPMENT_TYPE.PHYSICAL_TERMINAL){
                list[index]['connection_type']='Ethernet(Internet)'
                list[index]['auto_close']=selectedBankOption&&auto_close_default[selectedBankOption]?auto_close_default[selectedBankOption]:equip_default_obj['auto_close']
            }else{
                list[index]['connection_type']=''
                list[index]['auto_close']='22:00'
            }
        }
        if(data && data.name && data.name === "application" && list[index].equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL){
            list[index]['mobile_equipment']='None'
        }
        if (!!type) {
            list[index][type] = e
        } else if (data && data.name && data.hasOwnProperty('value')) { // to handle the dropdown event
            const dd_name = data.name
            const dd_value = data.value
            list[index][dd_name] = dd_value
        } else if (!!data && !!data.type && data.type === 'checkbox') {
            if(data.name==='customer_vault'&&!data.checked){
                list[index]['automatic_card_updater'] =false
            }
            list[index][data.name] = data.checked || false
        } else {
            const { name, value } = e.target
            if (name === "mobileNo") {
                let mobileNo = globalFormat(value);
                list[index][name] = mobileNo;
            } else {
                list[index][name] = value
            }
        }

        if (data && data.name === "product" && (list[index]['equipment_type'] === EQUIPMENT_TYPE.PHYSICAL_TERMINAL||list[index]['equipment_type'] === EQUIPMENT_TYPE.PIN_PAD)) {
            const cost = getProductCost(data.value)
            list[index]['cost'] = cost
            list[index]['quantity']=''
            if(list[index]['billTo'] !=="Merchant"){
                setMinEquipmentCost(parseFloat(cost))
            }
        }
        if (data && data.name === "quantity" && !!data.value
        && (list[index]['billTo'] ==="Merchant" || list[index]['billTo'] ==="Trinity")
        && (list[index]['equipment_type'] === EQUIPMENT_TYPE.PHYSICAL_TERMINAL||list[index]['equipment_type'] === EQUIPMENT_TYPE.PIN_PAD)
        &&list[index].product) {
                const cost:any = getProductCost(list[index].product)
                let quantity = data.value
                console.log("quantity quantitiy:::",quantity)
                let costByQuantity = quantity * cost
                list[index]['cost'] = parseFloat(costByQuantity?.toString()||"")
                setMinEquipmentCost(list[index]['cost'])
            
        }
        if (data && data.name === "billTo" 
        && (list[index]['billTo'] ==="Merchant" || list[index]['billTo'] ==="Trinity")
        && (list[index]['equipment_type'] === EQUIPMENT_TYPE.PHYSICAL_TERMINAL||list[index]['equipment_type'] === EQUIPMENT_TYPE.PIN_PAD) 
        && list[index].product
        && list[index].quantity) {
            const cost:any = getProductCost(list[index].product)
            let quantity = list[index].quantity
            console.log("quantity billTo:::",quantity)
            let costByQuantity = quantity * cost
            list[index]['cost'] = parseFloat(costByQuantity?.toString()||"")
            setMinEquipmentCost(list[index]['cost'])
        
        }

        if (data && data.name === "mobile_equipment" 
        && data.value !== "None" 
        && (list[index]['equipment_type'] === EQUIPMENT_TYPE.VIRTUAL_TERMINAL)) {
            const cost = getProductCost(data.value)
            list[index]['cost'] = cost
            list[index]['quantity']=''
            if(list[index]['billTo'] !=="Merchant"){
                setMinEquipmentCost(parseFloat(cost))
            }
        }
        if (data 
            && data.name === "quantity" 
            && (list[index]['billTo'] ==="Merchant" || list[index]['billTo'] ==="Trinity")
            &&list[index].mobile_equipment
            &&list[index].mobile_equipment!== "None"
            && list[index]['equipment_type'] === EQUIPMENT_TYPE.VIRTUAL_TERMINAL
            ) {
                const cost:any = getProductCost(list[index].mobile_equipment)
                let quantity = data.value
                let costByQuantity = quantity * cost
                list[index]['cost'] = parseFloat(costByQuantity?.toString()||"")
                setMinEquipmentCost(list[index]['cost'])
            
        }
        if (data 
            && data.name === "billTo" 
            && (list[index]['billTo'] ==="Merchant" || list[index]['billTo'] ==="Trinity")
            && list[index]['equipment_type'] === EQUIPMENT_TYPE.VIRTUAL_TERMINAL
            &&list[index].mobile_equipment
            &&list[index].mobile_equipment!== "None"
            && list[index].quantity) {
            const cost:any = getProductCost(list[index].mobile_equipment)
            let quantity = list[index].quantity
            let costByQuantity = quantity * cost
            list[index]['cost'] = parseFloat(costByQuantity?.toString()||"")
            setMinEquipmentCost(list[index]['cost'])        
        }
        setEquipmentSectionData(list)
    }

    const handleEquipCostBlur = (e: any, index: any) => {
        const list: any = [...equipmentSectionData]
        const floatCost = parseFloat(list[index]['cost'])
        const updatedCost = floatCost.toFixed(2);
        list[index]['cost'] = updatedCost

        setEquipmentSectionData(list)
    }

    // handle click event of the Remove button
    const handleRemoveEquipClick = (index: any) => {
        const list = [...equipmentSectionData]
        list.splice(index, 1)
        setEquipmentSectionData(list)
        // after removing any owner data set fiest owner as active
        handleEquipmentClick(0)
    }

    // handle click event of the Add button
    const handleAddEquipClick = (index: any) => {
        handleEquipmentClick(equipmentSectionData.length);
        setEquipmentSectionData([
            ...equipmentSectionData,
            equip_default_obj
        ])
    }

    const handleEquipmentClick = (index: any) => {
        setEquipmentActive(index)
        setHideEquipment(true)
    }

    return (
        <div className='form-fields equipment-info-section'>
            <div className='ui form'>
                <div className={!isTab ? 'inline fields mb-14' : 'tab-flex-button inline fields'}>{renderEquipmentButton(merchantWithEquip)}</div>

                <div>
                    {
                        equipmentSectionData.map((item, i) => {
                            let disableFlag = ((role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role=== ROLES.SALES_LIMITED) || !!tokenID || (!item?.rm_added && !role)) ? false : true
                            let disableFlagOptional = (!(item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.product === "NMI" && item.application === APPLICATIN_TYPE.MOBILE_ONLY) && (role === ROLES.SUPER_ADMIN || role === ROLES.PARTNER || role === ROLES.UNDER_WRITER || role === ROLES.RELATIONSHIP_MANAGER || role === ROLES.OFFICE_MANAGER || role === ROLES.OFFICE_ADMIN || role=== ROLES.SALES_LIMITED) || !!tokenID || (!item?.rm_added && !role))? false : true 
                            let optionalFieldCheck = item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.product === "NMI" && item.application === APPLICATIN_TYPE.MOBILE_ONLY                   
                            if (equipmentActive !== i) return null;              
                            if(item.application === APPLICATIN_TYPE.MOBILE_ONLY){
                                disableFlagOptional = true
                            }
                            return (
                                <div>
                                    <div className='fields'>
                                        <div className='eight wide field'>
                                            <label>Type <span className="required-text"> *</span> </label>
                                            <Dropdown
                                                disabled={disableFlag}
                                                placeholder={isMobile? 'Type' : 'Choose One'}
                                                className={ !item.equipment_type ? 'not-filled' :'form-input'}
                                                name='equipment_type'
                                                fluid
                                                search
                                                selection
                                                options={equipmentTerminal}
                                                value={item.equipment_type}
                                                onChange={(e, data) => handleEquipChange(e, i, data)}
                                            />
                                        </div>
                                        <div className='eight wide field'>
                                            <label>Deployment <span className="required-text"> *</span> </label>
                                            <Dropdown
                                                disabled={disableFlag}
                                                placeholder={isMobile? 'Deployment ' : 'Choose One'}
                                                className={ !item.deployment ? 'not-filled' :'form-input'}
                                                name='deployment'
                                                fluid
                                                search
                                                selection
                                                options={riskLevel === RISK_LEVELS.HIGH_RISK && item.equipment_type !== EQUIPMENT_TYPE.POS ? equipment_deployment[item.equipment_type]?.filter(item => item.value !== 'VAR Only') : equipment_deployment[item.equipment_type]}
                                                value={item.deployment}
                                                onChange={(e, data) => handleEquipChange(e, i, data)}
                                            />
                                        </div>
                                    </div>

                                    
                                    {item.equipment_type === EQUIPMENT_TYPE.CHECK_READER && (
                                        <div className='fields'>
                                            <div className='eight wide field'>
                                                <label>Check Reader Make/Model<span className="required-text"> *</span></label>
                                                <input
                                                    disabled={disableFlag}
                                                    type='text'
                                                    name='check_reader_text'
                                                    className={ !item.check_reader_text ? 'not-filled' :'form-input'}
                                                    placeholder='Check Reader Make/Model'
                                                    value={item.check_reader_text}
                                                    autoComplete="new-password"
                                                    onChange={(e) => handleEquipChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {item.equipment_type === EQUIPMENT_TYPE.POS && (
                                        <div>
                                            <div className='fields'>
                                            {selectedSellTime !== 'Cash Discount'&&<div className='eight wide field'>
                                                    <label>POS Name <span className="required-text"> *</span> </label>
                                                    <input
                                                        disabled={disableFlag}
                                                        type='text'
                                                        name='pos_name'
                                                        className={ !item.pos_name ? 'not-filled' :'form-input'}
                                                        placeholder='POS Name'
                                                        value={item.pos_name}
                                                        autoComplete="new-password"
                                                        onChange={(e) => handleEquipChange(e, i)}
                                                    />
                                                </div>}
                                                {selectedSellTime === 'Cash Discount'&&(
                                                    <div className='eight wide field'>
                                                    <label>POS Name <span className="required-text"> *</span> </label>
                                                    <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile? 'POS Name' : 'Choose One'}
                                                    className={ !item.pos_name ? 'not-filled' :'form-input'}
                                                    name='pos_name'
                                                    fluid
                                                    search
                                                    selection
                                                    //options={(item.equipment_type === "Virtual Terminal") ? condProductOptions : productOptions}
                                                    options={getProductOptions(item.equipment_type)}
                                                    value={item.pos_name}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                                </div>
                                                    )}
                                                <div className='eight wide field'>
                                                    <label>Version # (when available)</label>
                                                    <input
                                                        disabled={disableFlag}
                                                        type='text'
                                                        name='pos_version'
                                                        className={'form-input'}
                                                        placeholder='Version #'
                                                        value={item.pos_version}
                                                        autoComplete="new-password"
                                                        onChange={(e) => handleEquipChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(
                                        <div className='fields'>
                                            {((item.equipment_type !== EQUIPMENT_TYPE.CHECK_READER  && item.equipment_type !== EQUIPMENT_TYPE.POS) && <div className='eight wide field'>
                                                <label>Product<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile? 'Product' : 'Choose One'}
                                                    className={ !item.product ? 'not-filled' :'form-input'}
                                                    name='product'
                                                    fluid
                                                    search
                                                    selection
                                                    //options={(item.equipment_type === "Virtual Terminal") ? condProductOptions : productOptions}
                                                    options={getProductOptions(selectedSellTime === 'Cash Discount' 
                                                    && item.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL
                                                    ?`Cash Discount ${item.equipment_type}`:
                                                    selectedSellTime === 'Cash Discount' 
                                                    && item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL
                                                    ?`Cash Discount ${item.equipment_type}`
                                                    :item.equipment_type)}
                                                    value={item.product}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>)}
                                            {(item.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL) && (<div className='eight wide field'>
                                                <label>Equipment Quantity<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile? 'Equipment Quantity' : 'Choose One'}
                                                    className={ !item.quantity ? 'not-filled nowrap' :'form-input nowrap'}
                                                    name='quantity'
                                                    fluid
                                                    search
                                                    selection
                                                    options={equipment_quantity}
                                                    value={item.quantity}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>)}
                                                {(item.equipment_type === EQUIPMENT_TYPE.PIN_PAD) && 
                                                (<div style={{ display:role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED && !tokenID ? 'none' : 'grid' }}
                                                className='eight wide field'>
                                                <label>Bill To<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile? 'Bill To' : 'Choose One'}
                                                    className={ !item.billTo ? 'not-filled' :'form-input'}
                                                    name='billTo'
                                                    fluid
                                                    search
                                                    selection
                                                    options={equipment_billTo}
                                                    value={item.billTo}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>)}
                                        </div>
                                    )}
                                    {item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.product === 'Other' && (
                                        <div className='fields'>
                                            <div className='eight wide field'>
                                                <label>Gateway Name<span className="required-text"> *</span></label>
                                                <input
                                                    disabled={disableFlag}
                                                    type='text'
                                                    name='virtual_terminal_other'
                                                    className={ !item.virtual_terminal_other ? 'not-filled' :'form-input'}
                                                    placeholder='Gateway Name'
                                                    value={item.virtual_terminal_other}
                                                    autoComplete="new-password"
                                                    onChange={(e) => handleEquipChange(e, i)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    
                                     {(
                                        <div className='fields'>
                                           {(item.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL) && (<div style={{ display: role !== ROLES.SUPER_ADMIN && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.PARTNER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.UNDER_WRITER && role !== ROLES.SALES_LIMITED && !tokenID? 'none' : 'grid' }}
                                                className='eight wide field'>
                                                <label>Bill To<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile? 'Bill To' : 'Choose One'}
                                                    className={ !item.billTo ? 'not-filled' :'form-input'}
                                                    name='billTo'
                                                    fluid
                                                    search
                                                    selection
                                                    options={equipment_billTo}
                                                    value={item.billTo}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>)}
                                            {(item.equipment_type !== EQUIPMENT_TYPE.VIRTUAL_TERMINAL) && (item.deployment === "New") && (<div className='eight wide field'>
                                                <label>Shipping Address <span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    placeholder={isMobile? 'Shipping Address' : 'Choose One'}
                                                    className={ !item.equipment_mailing_addr_type ? 'not-filled' :'form-input'}
                                                    name='equipment_mailing_addr_type'
                                                    fluid
                                                    search
                                                    selection
                                                    options={mailing_address}
                                                    value={item.equipment_mailing_addr_type}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>)}
                                            {(item.equipment_type ===EQUIPMENT_TYPE.PIN_PAD) && (<div className='eight wide field'>
                                                <label>Equipment Quantity<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile? 'Equipment Quantity' : 'Choose One'}
                                                    className={ !item.quantity ? 'not-filled nowrap' :'form-input nowrap'}
                                                    name='quantity'
                                                    fluid
                                                    search
                                                    selection
                                                    options={equipment_quantity}
                                                    value={item.quantity}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>)}
                                        </div>
                                    )}
                                   {((item.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL || item.equipment_type===EQUIPMENT_TYPE.PIN_PAD) && 
                                   (item.billTo === 'Merchant' || item.billTo === 'Trinity') && (
                                        <div className='fields'>
                                            <div className='sixteen wide field product-cost-wrapper'>
                                                <label className="product-cost-label1">
                                                    {(item.billTo === 'Merchant' || item.billTo === 'Trinity') ? "Total Equipment Cost":"Equipment Cost"} </label>
                                                <div className="input-group prefix">
                                                    <span className="input-group-addon">$</span>
                                                    <input
                                                        disabled={role !== ROLES.SUPER_ADMIN && role !== ROLES.PARTNER && role !== ROLES.UNDER_WRITER && role !== ROLES.RELATIONSHIP_MANAGER && role !== ROLES.OFFICE_MANAGER && role !== ROLES.OFFICE_ADMIN && role !== ROLES.SALES_LIMITED && !tokenID}
                                                        type='number'
                                                        name='cost'
                                                        className= { !item.cost ? 'not-filled' :'form-input'}
                                                        placeholder={(item.billTo === 'Merchant' || item.billTo === 'Trinity')?"Total Equipment Cost":"Equipment Cost"}
                                                        value={item.cost}
                                                        autoComplete="new-password"
                                                        min={minEquipmentCost}
                                                        step='1'
                                                        onChange={(e) => handleEquipChange(e, i)}
                                                        onBlur={(e) => handleEquipCostBlur(e, i)}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 38 || e.keyCode === 40) {
                                                                return true;
                                                            }
                                                            return false;
                                                        }}
                                                    />
                                                </div>
                                                {/* <label className="product-cost-label2"> (+ $99 setup and shipping fee)</label> */}
                                            </div>
                                        </div>
                                    )
                                    )}
                                    
                                    <div style={{ padding: '10px 10px',color: config?.client_config_fe?.button_color }} className='form-headingleftpadding show-section-title'>Setup Details:</div>
                                    <hr style={{ marginLeft: '2px',color: config?.client_config_fe?.button_color ,border:'1px solid' }} className='top' />
                                    
                                    {(item.equipment_type ===EQUIPMENT_TYPE.PHYSICAL_TERMINAL) && (
                                        <div className='fields'>
                                            <div className='eight wide field'>
                                                <label>Connection Type<span className="required-text"> *</span></label>
                                                <Dropdown
                                                    placeholder={isMobile? 'Connection Type' : 'Choose One'}
                                                    className={ !item.connection_type ? 'not-filled' :'form-input'}
                                                    name='connection_type'
                                                    fluid
                                                    search
                                                    selection
                                                    options={item.equipment_type ===EQUIPMENT_TYPE.PHYSICAL_TERMINAL?connection_type_physical: connection_type}
                                                    value={item.connection_type}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>
                                            <div className='eight wide field'
                                                style={{ pointerEvents: 'auto', opacity: 'initial' }}>
                                                <label>{item.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL ?"Auto Batch (EST)":"Auto Close (CST)"}</label>
                                                <TimePicker 
                                                    defaultValue={dayjs(item.auto_close, format)}
                                                    minuteStep={30}
                                                    inputReadOnly={true}
                                                    onChange={(value, dateString) => {
                                                        handleEquipChange(dateString, i, null, 'auto_close')
                                                    }}
                                                    format={format} />
                                            </div>
                                        </div>
                                    )}
                                    {(item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL || item.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL) && (
                                        <div className='fields'>
                                            <div className='eight wide field'>
                                                <label>Application<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile ? 'Application' : 'Choose One'}
                                                    className={!item.application ? 'not-filled nowrap' : 'form-input nowrap'}
                                                    name='application'
                                                    fluid
                                                    search
                                                    selection
                                                    options={item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.product === "NMI"? application_type_nmi : selectedBankOption === BANK_CODES.CFSB && riskLevel === RISK_LEVELS.HIGH_RISK ? equipment_application_CFSB_High_Risk[item.equipment_type] : equipment_application[item.equipment_type]}
                                                    value={item.application}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>
                                            {/* {item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.application === APPLICATIN_TYPE.MOBILE_ONLY && (
                                                <div className='eight wide field'>
                                                    <label>Mobile Equipment<span className="required-text"> *</span> </label>
                                                    <Dropdown
                                                        disabled={disableFlag}
                                                        placeholder={isMobile ? 'Mobile Equipment' : 'Choose One'}
                                                        className={!item.application ? 'not-filled nowrap' : 'form-input nowrap'}
                                                        name='mobile_equipment'
                                                        fluid
                                                        search
                                                        selection
                                                        options={mobileEquipmentOptions}
                                                        value={item.mobile_equipment}
                                                        onChange={(e, data) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                            )} */}
                                        </div>)}
                                    {(item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.application===APPLICATIN_TYPE.MOBILE_ONLY && item.mobile_equipment !== "None") && (
                                        <div className='fields'>
                                            <div className='eight wide field'>
                                                <label>Equipment Quantity<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile ? 'Equipment Quantity' : 'Choose One'}
                                                    className={!item.quantity ? 'not-filled nowrap' : 'form-input nowrap'}
                                                    name='quantity'
                                                    fluid
                                                    search
                                                    selection
                                                    options={equipment_quantity}
                                                    value={item.quantity}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>
                                            <div
                                                className='eight wide field'>
                                                <label>Bill To<span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    disabled={disableFlag}
                                                    placeholder={isMobile ? 'Bill To' : 'Choose One'}
                                                    className={!item.billTo ? 'not-filled' : 'form-input'}
                                                    name='billTo'
                                                    fluid
                                                    search
                                                    selection
                                                    options={equipment_billTo}
                                                    value={item.billTo}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>
                                        </div>)}
                                        {(
                                        <div className='fields'>
                                            
                                            {(item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.application===APPLICATIN_TYPE.MOBILE_ONLY && item.mobile_equipment !== "None") && (<div className='eight wide field'>
                                                <label>Shipping Address <span className="required-text"> *</span> </label>
                                                <Dropdown
                                                    placeholder={isMobile? 'Shipping Address' : 'Choose One'}
                                                    className={ !item.equipment_mailing_addr_type ? 'not-filled' :'form-input'}
                                                    name='equipment_mailing_addr_type'
                                                    fluid
                                                    search
                                                    selection
                                                    options={mailing_address}
                                                    value={item.equipment_mailing_addr_type}
                                                    onChange={(e, data) => handleEquipChange(e, i, data)}
                                                />
                                            </div>)}
                                        </div>
                                    )}
                                        {((item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.mobile_equipment !== "None") && 
                                        (item.billTo === 'Merchant' || item.billTo === 'Trinity') && (
                                        <div className='fields'>
                                            <div className='sixteen wide field product-cost-wrapper'>
                                                <label className="product-cost-label1">{item?.billTo === "Merchant" ? "Total Equipment Cost" : "Equipment Cost"} </label>
                                                <div className="input-group prefix">
                                                    <span className="input-group-addon">$</span>
                                                    <input
                                                        disabled={disableFlag}
                                                        type='number'
                                                        name='cost'
                                                        className='form-input'
                                                        placeholder={item?.billTo==="Merchant"?"Total Equipment Cost":"Equipment Cost"}
                                                        value={item.cost}
                                                        autoComplete="new-password"
                                                        min={minEquipmentCost}
                                                        step='1'
                                                        onChange={(e) => handleEquipChange(e, i)}
                                                        onBlur={(e) => handleEquipCostBlur(e, i)}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 38 || e.keyCode === 40) {
                                                                return true;
                                                            }
                                                            return false;
                                                        }}
                                                    />
                                                </div>
                                                {/* <label className="product-cost-label2"> (+ $99 setup and shipping fee)</label> */}
                                            </div>
                                        </div>
                                    )
                                    )}
                                    
                                    
                                    {(item.equipment_type === EQUIPMENT_TYPE.POS) && (
                                        <div className='fields grid-align-equip check-box' >
                                            <div className='eight wide field'>
                                                <Checkbox
                                                    disabled={disableFlag}
                                                    label='Tokenization Needed'
                                                    name='tokenization_needed'
                                                    checked={item.tokenization_needed || false}
                                                    onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    
                                    {item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL && item.application === APPLICATIN_TYPE.MOBILE_ONLY && <div className='warning-text-message'>Please add a mobile compatible device for Equipment 2 above</div>}
                                    {(item.equipment_type === EQUIPMENT_TYPE.VIRTUAL_TERMINAL) && (

                                        <><div className='fields' >
                                            <div className='sixteen field'>
                                                <label className='show-label'>Optional features below are not available on Mobile Only gateways and carry additional cost/fees per item. Contact your Sales Rep/Relationship Manager for pricing information.</label>
                                            </div>
                                        </div>
                                            <div className='fields' >
                                                <div className='sixteen wide field'>
                                                    <label className='show-label'>Select all that are needed:</label>
                                                </div>
                                            </div>
                                            <div className='fields'>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlagOptional}
                                                        label='ACH/Electronic Check'
                                                        name='ach_electronic_check'
                                                        checked={optionalFieldCheck ? false : item.ach_electronic_check || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlagOptional}
                                                        label='Customer Vault'
                                                        name='customer_vault'
                                                        checked={optionalFieldCheck ? false : item.customer_vault || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='fields'>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlagOptional}
                                                        label='Invoicing'
                                                        name='invoicing'
                                                        checked={optionalFieldCheck ? false : item.invoicing || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlagOptional}
                                                        label='Level III Processing'
                                                        name='level_three_processing'
                                                        checked={optionalFieldCheck ? false : item.level_three_processing || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='fields'>
                                            <div className='sixteen wide field'>
                                                        <Checkbox
                                                            disabled={disableFlagOptional}
                                                            label='Automatic Card Updater (requires Customer Vault)'
                                                            name='automatic_card_updater'
                                                            checked={optionalFieldCheck ? false : item.automatic_card_updater || false}
                                                            onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                        />
                                                    </div>
                                                
                                            </div></>
                                    )}
                                    {(item.equipment_type === EQUIPMENT_TYPE.PIN_PAD) && (
                                        <div className='fields grid-align-equip check-box'>
                                            <div className='eight wide field'>
                                                <Checkbox
                                                    disabled={disableFlag}
                                                    label='Key Injection Needed'
                                                    name='key_injection_needed'
                                                    checked={item.key_injection_needed || false}
                                                    onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {(item.equipment_type === EQUIPMENT_TYPE.PHYSICAL_TERMINAL) && (
                                        <>
                                            <div className='fields' >
                                                <div className='sixteen wide field'>
                                                    <label className='show-label'>Select all that are needed:</label>
                                                </div>
                                            </div>
                                            <div className='fields grid-align-equip check-box' >
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        label='Invoice Number'
                                                        name='invoice_number'
                                                        checked={item.invoice_number || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        label='Server/Clerk#'
                                                        name='server_clerk'
                                                        checked={item.server_clerk || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='fields grid-align-equip check-box mb-14' >
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlag}
                                                        label='CVV2'
                                                        name='cvv2'
                                                        checked={item.cvv2 || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlag}
                                                        label='AVS'
                                                        name='avs'
                                                        checked={item.avs || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='fields'>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlag}
                                                        label='Tip Option'
                                                        name='tip_option'
                                                        checked={item.tip_option || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlag}
                                                        label='Multi Merchant'
                                                        name='multi_merchant'
                                                        checked={item.multi_merchant || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='fields'>
                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlag || pinDebit === "No"}
                                                        label='Pin Debit'
                                                        name='pin_debit'
                                                        checked={riskLevel !== RISK_LEVELS.HIGH_RISK? pinDebit === "Yes" : item.pin_debit || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>

                                                <div className='eight wide field'>
                                                    <Checkbox
                                                        disabled={disableFlag}
                                                        label='Cash Back Option'
                                                        name='cash_back_option'
                                                        checked={item.cash_back_option || false}
                                                        onChange={(e, data: CheckboxProps) => handleEquipChange(e, i, data)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='fields'>
                                                <div className='sixteen wide field'>
                                                    <label>Receipt Footer Info (optional)</label>
                                                    <input
                                                        disabled={disableFlag}
                                                        type='text'
                                                        name='receipt_footer_info'
                                                        className='form-input'
                                                        placeholder='Receipt Footer Info (optional)'
                                                        value={item.receipt_footer_info}
                                                        autoComplete="new-password"
                                                        onChange={(e) => handleEquipChange(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        </>)}

                                    <div className='fields'>
                                        <div className='sixteen wide field'>
                                            <label>Special Instructions</label>
                                            <input
                                                disabled={disableFlag}
                                                type='text'
                                                name='special_instructions'
                                                className='form-input'
                                                placeholder='Special Instructions'
                                                value={item.special_instructions}
                                                autoComplete="new-password"
                                                onChange={(e) => handleEquipChange(e, i)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                </div>

            </div>
        </div>
    )
}

export default memo(EquipmentInformationSection)
